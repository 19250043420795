.admin-page-component{
    margin: 2% auto;
    color: white;
}
.form-data-container{
    width: 100%;
    margin: auto;
}
.form-data-heading{
    margin:2% auto;
    text-align: center;
    color: aqua;
}
.from-data-inner-container{
    display: flex;
    width: 95%;
    margin: 2% auto;
    justify-content: space-between;
    flex-wrap: wrap;
}
.inner-form-container-get-ul{
    width: 25%;
    padding: 20px 10px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(6px);
    color: #fff;
    padding: 30px 30px;
    box-shadow: 0 0 5px rgba(0, 255, 255, 0.5), 0 0 10px rgba(0, 255, 255, 0.7), 0 0 20px rgba(0, 255, 255, 0.9);
    list-style-type: none;
}
.inner-form-container-get-ul li{
  margin: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.from-data-get-li-span-1{
    color: rgb(255, 8, 8);
    width: 30%;
   
}
.form-data-get-li-span-2{
   /* padding: 0 5px; */
   width: 65%;
}
.form-data-expend{
        display: block;
        margin: auto;
        width: 15%;
        height: 35px;
        background: #fff;
        border: none;
        outline: none;
        border-radius: 40px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        cursor: pointer;
        color: #333;
        font-weight: 600;
        transition: all .5 ease;
}
.form-data-expend:hover{
    background: rgba(255, 255, 255, .2);
    color: #fff;
    border-color: #fff;
}