/* Phone */
@media only screen and (max-width: 600px) {

    .home p {
        width: 93%;
    }
    .skills-section-home-container {
        flex-direction: column;
    }
    .each-box-of-skill {
        width: 78%;
        margin: 3% auto;
        padding: 4%;
    }
    .project-container-each {
        flex-direction: column;
    }
    .first-box-of-project-for-details, .third-container-of-project-for-mobile-view {
        width: 90%;
    }
    .second-box-of-project-for-desktop-image {
        width: 90%;
    }
    .skills-container {
        flex-direction: column;
    }
    .skill {
        width: 90%;
        margin: 3% auto;
    }
    .contact {
        width: 76%;
        margin: auto;
    }
    .social-icons-container {   
        bottom: 5%;
    }
    .about-me-and-right-reserve {
        flex-direction: column;
    }
    .aboutme-container {
        width: 90%;
        padding: 2%;
        margin: auto;
    }
    .all-right-reserve-container {
        width: 90%;
        margin: auto;
        padding-bottom: 45px;
    }
    .admin-login-navlink {
        bottom: 15px;
        right: 38%;
    }
    .all-right-reserve-heading {
        font-size: 1.2rem;
        color: #8b8b8b;
    }
    .about-me-text {
        width: 90%;
    }
    .wrapper .btn {
        width: 40%;
        height: 37px;
    }
    .navbar {
       display: none;
    }
    .navbar ul {
        list-style: none;
        display: flex;
        /* padding: 19px 32px; */
        width: 95%;
        margin: auto;
    }
    .navbar ul li {
        margin: 0 17px;
       
    }
    .about-me-image-container {
        width: 50%;
        margin: auto;
    }
    .form-error-msg-span {
        left: 34px;
    }
    .project-container-each {
        width: 93%;
    }

    .wrapper .input-box {
        position: relative;
        width: 96%;
        /* height: 37px; */
        margin: 15px auto;
    }
    .input-box input {
        width: 73%;
        background: transparent;
        font-size: 16px;
        color: #fff;
        padding: 15px 20px;
        margin: auto;
        border: none;
        outline: none;
        border: 2px solid rgba(255, 255, 255, .2);
        border-radius: 40px;
    }
}